<template>
  <dx-scroll-view height="100%" width="100%" class="with-footer single-card">
    <video id="background-video" autoplay loop muted>
      <source src="https://sistema.cwsweb.com.br/bg.mp4" type="video/mp4">
    </video>
    <div class="dx-card content" :style="{width: largura_tela+'px'}">
      <img class="imagem" src="../assets/logo_.png" alt="">
      <div class="header">
        <div class="description">Sistema online de Gerenciamento do seu Software</div>
      </div>
      <slot/>
    </div>
  </dx-scroll-view>
</template>

<script>
import DxScrollView from "devextreme-vue/scroll-view";

import { useRoute } from 'vue-router';
import { watch, ref } from 'vue';

export default {
  data(){
    return{
      largura_tela: 530
    }
  },
  components: {
    DxScrollView
  },
  watch: {
    '$route.path': function () {
        var rota = this.$router.currentRoute.value
        if(rota.path == '/login-form'){
          this.largura_tela =530
        }
        else{
          this.largura_tela = 830
        }
    }
  },
  setup() {
    const route = useRoute();

    const title = ref(route.meta.title);
    const description = ref("");

    watch(() => route.path,
     () => {
        title.value = route.meta.title;
        description.value = route.meta.description;
     }
    )
    return {
      title,
      description
    }
  }
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";

#background-video {
   width: 100vw;
   height: 100vh;
   object-fit: cover;
   position: fixed;
   left: 0;
   right: 0;
   top: 0;
   bottom: 0;
   z-index: -1;
}

.single-card {
  width: 100%;
  height: 100%;
  background-color: #1184b29b;
  background-size: cover;
  align-items: center;
  text-align: center;
  background-repeat: no-repeat;
  background-blend-mode: lighten;

  .dx-card {
    width: 530px;
    margin: auto auto;
    padding: 40px;
    flex-grow: 0;

    .screen-x-small & {
      width: 100%;
      height: 100%;
      border-radius: 0;
      box-shadow: none;
      margin: 0;
      border: 0;
      flex-grow: 1;
    }

    .header {
      margin-bottom: 30px;

      .title {
        color: $base-text-color;
        line-height: 28px;
        font-weight: 500;
        font-size: 24px;
      }

      .description {
        color: rgba($base-text-color, alpha($base-text-color) * 0.7);
        line-height: 18px;
      }
    }
  }
}
.imagem{
  height: 50px;
  align-items: center;
}
</style>